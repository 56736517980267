const Menu = [
    {
        heading: 'Navegación'        
    },
    {
        name: 'Ingresar Venta',
        path: 'sellproduct',
        icon: 'icon-basket-loaded'
    },
    {
        name: 'Ingresar Compra',
        path: 'buyproduct',
        icon: 'icon-drawer'        
    },
    {
        name: 'Ingresar Merma',
        path: 'decreaseproduct',
        icon: 'icon-trash'        
    },
    {
        name: 'Gestión Clientes',
        path: 'customers',
        icon: 'icon-people'        
    },
    {
        name: 'Gestión Categorías',
        path: 'categories',
        icon: 'icon-notebook'        
    },
    {
        name: 'Gestión Productos',
        path: 'products',
        icon: 'icon-social-dropbox'        
    },
    {
        name: 'Reporte de Ventas',
        path: 'sellreport',
        icon: 'icon-chart'        
    },
    {
        name: 'Reporte de Compras',
        path: 'buyreport',
        icon: 'icon-briefcase'        
    },
    /* {
        name: 'Sistema',
        icon: 'icon-settings',
        submenu: [
            {
                name: 'Usuarios',
                //path: 'submenu'
            },
            {
                name: 'Roles',
                //path: 'submenu'
            },
        ]
    }   */  
];

export default Menu;
