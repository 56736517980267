import React, { Suspense, lazy } from 'react';
import { useEffect } from 'react';
import { withRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const Login = lazy(() => import('./components/Pages/Login'));

const SellProduct = lazy(() => import('./components/Pages/SellProduct'));
const BuyProduct = lazy(() => import('./components/Pages/BuyProduct'));
const DecreaseProduct = lazy(() => import('./components/Pages/DecreaseProduct'));
const Customers = lazy(() => import('./components/Pages/Customers'));
const CustomerView = lazy(() => import('./components/Pages/CustomerView'));
const Categories = lazy(() => import('./components/Pages/Categories'));
const CategoryView = lazy(() => import('./components/Pages/CategoryView'));
const Products = lazy(() => import('./components/Pages/Products'));
const ProductView = lazy(() => import('./components/Pages/ProductView'));
const SellReport = lazy(() => import('./components/Pages/SellReport'));
const BuyReport = lazy(() => import('./components/Pages/BuyReport'));
const PrintComponent = lazy(() => import('./components/Pages/PrintComponent'));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/login'
];

const Routes = ({ location }) => {
    let currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    const history = useHistory();

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'

    const animationName = 'rag-fadeIn'

    useEffect(() => {

        let user = JSON.parse(localStorage.getItem('user'));
        if (!user || !user.email) history.push('/login');

    }, []);

    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path="/login" component={waitFor(Login)} />
                    </Switch>
                </Suspense>
            </BasePage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <Base>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <Route path="/sellproduct" component={waitFor(SellProduct)} />
                                    <Route path="/buyproduct" component={waitFor(BuyProduct)} />
                                    <Route path="/decreaseproduct" component={waitFor(DecreaseProduct)} />
                                    <Route path="/customers" component={waitFor(Customers)} />
                                    <Route path="/customerview/:id?" component={waitFor(CustomerView)} />
                                    <Route path="/categories" component={waitFor(Categories)} />
                                    <Route path="/categoryview/:id?" component={waitFor(CategoryView)} />
                                    <Route path="/products" component={waitFor(Products)} />
                                    <Route path="/productview/:id?" component={waitFor(ProductView)} />
                                    <Route path="/sellreport" component={waitFor(SellReport)} />
                                    <Route path="/buyreport" component={waitFor(BuyReport)} />
                                    <Route path="/print/:id?" component={waitFor(PrintComponent)} />

                                    <Redirect to="/login" />
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Base>
        )
    }
}

export default withRouter(Routes);
